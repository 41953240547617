<div ngbDropdown class="navbar-nav" display="dynamic" placement="bottom-right">
  <a href="javascript:void(0);" role="button"
     [attr.aria-label]="'nav.help-page' |translate"
     [title]="'nav.help-page' | translate" class="px-1"
     (click)="$event.preventDefault()" data-toggle="dropdown" ngbDropdownToggle
     tabindex="0">
    <i class="fas fa-circle-info fa-lg fa-fw"></i>
  </a>
  <div ngbDropdownMenu class="dropdown-menu px-4 py-3" [attr.aria-label]="'nav.help-page' | translate">
    <a routerLink="help/publication-and-research-data"
        fragment="publishing-requirements"
        [attr.aria-label]="'nav.help-page.step-by-step-instructions' | translate"
        [title]="'nav.help-page.step-by-step-instructions' | translate"
      class="dropdown-item px-1">{{'nav.help-page.step-by-step-instructions' | translate}}
    </a>
    <a routerLink="help/guidelines"
        [attr.aria-label]="'nav.help-page.guidelines' | translate"
        [title]="'nav.help-page.guidelines' | translate"
      class="dropdown-item px-1">{{'nav.help-page.guidelines' | translate}}
    </a>
    <a routerLink="help/publication-and-research-data"
        [attr.aria-label]="'nav.help-page.publication-and-research-data' | translate"
        [title]="'nav.help-page.publication-and-research-data' | translate"
        class="dropdown-item px-1">{{'nav.help-page.publication-and-research-data' | translate}}
    </a>
    <!-- <a routerLink="help/search"
        [attr.aria-label]="'nav.help-page.search' | translate"
        [title]="'nav.help-page.search' | translate"
        class="dropdown-item px-1">{{'nav.help-page.search' | translate}}
    </a> -->
    <a routerLink="help/rights-notices-and-licenses"
        [attr.aria-label]="'nav.help-page.usage-rights-and-licenses' | translate"
        [title]="'nav.help-page.usage-rights-and-licenses' | translate"
        class="dropdown-item px-1">{{'nav.help-page.usage-rights-and-licenses' | translate}}
    </a>
    <div class="dropdown-item px-1">
      <a href="{{'nav.help-page.dissertation-submission.url' | translate}}"
        [attr.aria-label]="'nav.help-page.dissertation-submission' | translate"
        [title]="'nav.help-page.dissertation-submission' | translate"
        target="_blank">{{'nav.help-page.dissertation-submission' | translate}} <i class="fa-solid fa-arrow-up-right-from-square fa-xs px-1"></i>
    </a>
    </div>
  </div>
</div>
