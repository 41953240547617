import { ResourcePolicyCheckboxEntry } from 'src/app/shared/resource-policies/entry/resource-policy-entry.component';
import { ResourcePolicy } from 'src/app/core/resource-policy/models/resource-policy.model';
import { ResourcePolicyDataService } from 'src/app/core/resource-policy/resource-policy-data.service';
import { filter, first, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ItemPageComponent } from '../simple/item-page.component';
import { MetadataMap } from '../../core/shared/metadata.models';
import { ItemDataService } from '../../core/data/item-data.service';

import { RemoteData } from '../../core/data/remote-data';
import { Item } from '../../core/shared/item.model';

import { fadeInOut } from '../../shared/animations/fade';
import { hasValue } from '../../shared/empty.util';
import { AuthService } from '../../core/auth/auth.service';
import { Location } from '@angular/common';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { followLink } from 'src/app/shared/utils/follow-link-config.model';
import { getAllCompletedRemoteData, getFirstSucceededRemoteData } from 'src/app/core/shared/operators';
import { ServerResponseService } from 'src/app/core/services/server-response.service';
import { SignpostingDataService } from 'src/app/core/data/signposting-data.service';
import { LinkHeadService } from 'src/app/core/services/link-head.service';

import { APP_CONFIG, AppConfig } from '../../../config/app-config.interface';
import { ConfigurationDataService } from 'src/app/core/data/configuration-data.service';
import { IdentifierDataService } from 'src/app/core/data/identifier-data.service';
import { Identifier } from 'src/app/shared/object-list/identifier-data/identifier.model';

/**
 * This component renders a crazy mode item page.
 * The route parameter 'id' is used to request the item it represents.
 */

@Component({
  selector: 'ubmz-crazy-item-page',
  styleUrls: ['./crazy-item-page.component.scss'],
  templateUrl: './crazy-item-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut]
})
export class CrazyItemPageComponent extends ItemPageComponent implements OnInit, OnDestroy {

  itemRD$: BehaviorSubject<RemoteData<Item>>;

  itemForIdentifierRD$: Observable<RemoteData<Item>>;

  metadata$: Observable<MetadataMap>;


  crazyData = {
    'Rest (JSON)': [],
    'OAI DC (XML)': ['oai_dc'],
    'OAI Datacite (XML)': ['datacite'],
    'xMetaDiss (XML)': ['xMetaDissPlus'],
    'epicur (XML)': ['epicur'],
    'XOAI (XML)': ['xoai'],
    'Openaire (XML)': ['oai_openaire']
  };

  submitter: string;

  crazyFileSectionData = {'originals' : 0,
                          'licenses': 0,
                          'originalFilePolicies': []};

  protected restHost: string;
  protected restNameSpace: string;
  protected restProtocol: string;
  protected restSsl: boolean;

  protected doiPrefix: string;
  protected doiNamespaceSeparator: string;
  protected doiId: string;

  /**
   * True when the itemRD has been originated from its workspaceitem/workflowitem, false otherwise.
   */
  fromSubmissionObject = false;

  /**
   * Array to track all subscriptions and unsubscribe them onDestroy
   * @type {Array}
   */
  private subs: Subscription[] = [];

  /**
   * Array to track all resource-policy subscriptions and unsubscribe them onDestroy
   * @type {Array}
   */
  private itemPolicySubs: Subscription[] = [];

  /**
   * The list of policies for given item
   * @type {BehaviorSubject<ResourcePolicyCheckboxEntry[]>}
   */
  private itemPoliciesEntries$: BehaviorSubject<ResourcePolicyCheckboxEntry[]> =
    new BehaviorSubject<ResourcePolicyCheckboxEntry[]>([]);

  protected identifiers$: Observable<Identifier[]>;

  constructor(protected route: ActivatedRoute,
              router: Router,
              items: ItemDataService,
              authService: AuthService,
              authorizationService: AuthorizationDataService,
              private _location: Location,
              private resourcePolicyService: ResourcePolicyDataService,
              private cdr: ChangeDetectorRef,
              private identifierDataService: IdentifierDataService,
              protected responseService: ServerResponseService,
              protected signpostingDataService: SignpostingDataService,
              protected linkHeadService: LinkHeadService,
              protected configService: ConfigurationDataService,
              @Inject(PLATFORM_ID) protected platformId: string,
              @Inject(APP_CONFIG) protected appConfig: AppConfig) {
      super(route, router, items, authorizationService, responseService, signpostingDataService, linkHeadService, platformId);
      this.restHost = this.appConfig.rest.host;
      this.restNameSpace = this.appConfig.rest.nameSpace;
      this.restSsl = this.appConfig.rest.ssl;
      this.restSsl ? this.restProtocol = 'https' : this.restProtocol = 'http';
  }

  /*** AoT inheritance fix, will hopefully be resolved in the near future **/
  ngOnInit(): void {
    super.ngOnInit();
    this.metadata$ = this.itemRD$.pipe(
      map((rd: RemoteData<Item>) => rd.payload),
      filter((item: Item) => hasValue(item)),
      map((item: Item) => item.metadata),);

      this.subs.push(this.route.data.subscribe((data: Data) => {
        this.fromSubmissionObject = hasValue(data.wfi) || hasValue(data.wsi);
      })
    );

    this.subs.push(this.itemRD$.subscribe((data) => {
      if (data.payload) {
        this.itemPolicySubs.push(this.resourcePolicyService.searchByResource(
          data.payload.uuid, null, false, true,
          followLink('eperson'), followLink('group')
        ).pipe(
          getAllCompletedRemoteData()
        ).subscribe((result) => {
          const entries = result.payload.page.map((policy: ResourcePolicy) => ({
            id: policy.id,
            policy: policy,
            checked: false
          }));
          this.itemPoliciesEntries$.next(entries);
          // TODO detectChanges still needed?
          this.cdr.detectChanges();
        }));
      }
    }));

    this.subs.push(this.metadata$.subscribe((data) => {
      let index = data['dc.description.provenance'].findIndex( (entry) => entry.value.startsWith('Submitted by'));
      if (index === -1) {
        this.submitter = '';
      } else {
        this.submitter = data['dc.description.provenance'][index].value.substring(0, data['dc.description.provenance'][index].value.indexOf('\n'));
      }
    }));

    this.itemForIdentifierRD$ = this.route.data.pipe(
      map((data) => data.dso),
      getFirstSucceededRemoteData()
    )as Observable<RemoteData<Item>>;
    this.itemForIdentifierRD$.pipe(first()).subscribe((rd) => {
      let item = rd.payload;
      this.identifiers$ = this.identifierDataService.getIdentifierDataFor(item).pipe(
        map((identifierRD) => {
          if (identifierRD.statusCode !== 401 && hasValue(identifierRD.payload)) {
            return identifierRD.payload.identifiers;
          } else {
            return null;
          }
        }),
      );
    });
  }

  getCrazySectionData(event) {
    this.crazyFileSectionData = event;
  }

  /**
   * Return all resource's policies
   *
   * @return an observable that emits all resource's policies
   */
  getItemResourcePolicies(): Observable<ResourcePolicyCheckboxEntry[]> {
    return this.itemPoliciesEntries$.asObservable();
  }

  /**
   * Navigate back in browser history.
   */
  back() {
    this._location.back();
  }

  ngOnDestroy() {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
    this.itemPolicySubs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
    this.itemPoliciesEntries$ = null;
  }
}
